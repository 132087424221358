<template>
  <div class="tc-measure">
    <!-- 排序 -->
    <div class="sort">
      <el-button type="plain" plain @click="showQrBox" size="mini"
        >生成二维码</el-button
      >
      <el-divider direction="vertical"></el-divider>
      <el-button type="plain" plain @click="edit(false)"
        >添加量表分组</el-button
      >
      <el-divider direction="vertical"></el-divider>
      <el-button type="plain" plain @click="upSort">排序</el-button>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border=""
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="group_name"
          label="分组名称"
          width="350"
          align="left"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="measure_count"
          label="量表统计(份)"
          align="center"
        ></el-table-column>
        <!-- <el-table-column prop="test_num" label="次数限制" align="center"></el-table-column> -->
        <el-table-column
          prop="status"
          label="分组状态"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            <span
              :class="scope.row.status == 2 ? 'off' : 'no'"
              @click="upstatus(scope.row)"
              >{{ scope.row.status | statusFilter }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="量表排序" align="center">
          <template slot-scope="scope">
            <!-- <el-input v-bind="scope.row.sort" /> -->
            <el-input
              v-model="scope.row.sort"
              size="mini"
              maxlength="2"
              @blur="submitSort(scope.row)"
              onkeyup="value=value.replace(/[^\d]/g,'') "
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="plain" @click="edit(scope.row.id)"
              >编辑</el-button
            >
            <el-divider direction="vertical"></el-divider>
            <el-button size="mini" type="plain" @click="remove(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 页码 -->
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-size="15"
        layout="total, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>

    <!-- 二维码显示模态框 -->
    <el-dialog
      :close-on-click-modal="false"
      title="生成量表二维码"
      :visible.sync="qrBox"
      width="40%"
      center
      :destroy-on-close="true"
    >
      <div class="qr">
        <div class="qr-show">
          <h2 @click="showQrQeury(1)">
            <span v-show="isqr1">隐藏</span>
            <span v-show="!isqr1">显示</span>二维码
          </h2>
          <el-collapse-transition>
            <div id="qrcode" ref="qrcode" v-show="isqr1"></div>
          </el-collapse-transition>
        </div>
        <!-- <div class="qr-hide">
          <h2 @click="showQrQeury(2)">
            <span v-show="isqr2">隐藏</span>
            <span v-show="!isqr2">显示</span>二维码(不可查看结果)
          </h2>
          <el-collapse-transition>
            <div id="qrcode2" ref="qrcode2" v-show="isqr2"></div>
          </el-collapse-transition>
        </div> -->
      </div>
      <div class="departmenselet">
        所属科室：
        <el-select
          v-model="departmenid"
          placeholder="请选择"
          size="mini"
          @change="departmenselect"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            >{{ item.name }}</el-option
          >
        </el-select>
      </div>
      <div style="margin-top: 20px">
        下载说明：请点击 - 鼠标右键- 选择 - 图片另存为
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="qrBox = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getMeasureGroupList,
  delMeasureGroupList,
  updateMeasureGroupList,

  getMeasureCode
} from "@/api/evaluate.js";
// 获取所有科室
import { loadDepartmentData } from "@/api/comm.js";
import QRCode from "qrcodejs2";
export default {
  inject: ["reload"],
  data() {
    return {
      // 二维码模态框参数
      isqr1: false,
      isqr2: false,
      qrBox: false,
      // 二维码量表组标识
      measure_token: "",
      // 科室
      departmentList: [],
      departmenid: "",
      departmenName: "",

      multipleSelection: [],
      tableData: [],
      // 页码
      page: 1,
      size: 15,
      // 总数
      total: 0,
      dialog: {
        state: 1, // 1 添加 2 编辑
        dialogVisible: true,
        form: {
          id: null,
          measure_name: "",
        },
        rules: {
          measure_name: [
            { required: true, message: "请输入分类名称！", trigger: "blur" },
          ],
        },
      },
      scaleData: [], // 存放量表列表的合子
      checkList: [],
      // 所有量表-是否有查看权限
      checkList2: [],
      measureForm: {
        rules: {},
        search: null,
        name: "", // 分组名
        state: "",
        measureList: "",
        testNum: "",
        showTested: "",
        note: "",
      },
      order: "sort desc",
    };
  },
  created() {
    this.loadMeasureListData();
    // this.loadScaleData();
  
    // 获取所有科室
    loadDepartmentData().then((res) => {
      this.departmentList = res.data.data;
    });

  },
  methods: {
    // 二维码处理
    // 显示二维码模态框
    showQrBox() {
      if (this.multipleSelection.length <= 0) {
        this.$message({
          showClose: true,
          message: "请先勾选量表",
          type: "warning",
        });
        return false;
      } else if(this.multipleSelection.length >1){
        this.$message({
          showClose: true,
          message: "请勾选单个量表分组",
          type: "warning",
        });
        return false;
      } else{
        // console.log(this.multipleSelection.toString(),"2222");
        getMeasureCode({ gid: this.multipleSelection.toString() }).then(
          (res) => {
            if (res.code == 400200) {
              this.qrBox = true;
              this.$nextTick(() => {
                this.measure_token = res.data;
                // console.log(res.data,"MMM");
                this.qrcode();
              });
            }
          }
        );
      }
    },

   // 生成二维码
    qrcode() {
      // let meaids = this.multipleSelection.toString();
      // let url = window.location.protocol + "//" + window.location.host;
      let config = JSON.parse(localStorage.getItem("config"));
      let url = config.localhost;
      // url="http://cqrmyy.cqsqy.com"//改完注销
      // console.log(url);
      let qrcode = new QRCode("qrcode", {
        width: 200, // 设置宽度，单位像素
        height: 200, // 设置高度，单位像素
        text: `${url}/index.html#/answer_content/answer_list?meaids=${this.measure_token}&meaidsshow=1&depId=${this.departmenid}&depName=${this.departmenName}`, // 设置二维码内容或跳转地址
      });
      // console.log("111",qrcode);
      let  box = {"meaids":this.measure_token,"meaidsshow":1,"depId":this.departmenid,"depName":this.departmenName}
      // console.log("222",box);
      localStorage.setItem(`qrcode`, JSON.stringify(box))
      let qrcode2 = new QRCode("qrcode2", {
        width: 200, // 设置宽度，单位像素
        height: 200, // 设置高度，单位像素
        text: `${url}/index.html#/answer_content/answer_list?meaids=${this.measure_token}&meaidsshow=0&depId=${this.departmenid}&depName=${this.departmenName}`, // 设置二维码内容或跳转地址
      });


    },
    // 二维码,科室选择
    departmenselect() {
      this.qrBox = false;
      this.departmentList.forEach((v) => {
        if (v.id == this.departmenid) {
          this.departmenName = v.name;
          return;
        }
      });
      this.$nextTick(() => {
        this.qrBox = true;
        setTimeout(() => {
          this.qrcode();
        }, 300);
      });
    },

    // 控制可查看不可查看二维码的选择
    showQrQeury(val) {
      if (val == 1) {
        if (this.isqr1) {
          this.isqr1 = false;
        } else {
          this.isqr1 = true;
        }
      } else {
        if (this.isqr2) {
          this.isqr2 = false;
        } else {
          this.isqr2 = true;
        }
      }
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
      let p = [];
      this.multipleSelection.forEach((v) => {
        p.push(v.id);
      });
      this.multipleSelection = p;
    },
    handleSizeChange(val) {
      //console.log(`每页 ${val} 条`);
    },
    // 页码监听
    handleCurrentChange(val) {
      // 根据page加载对应数据
      this.loadmeasureListData(val);
    },
    // 刷新
    refresh() {
      this.reload();
    },
    inputNum(val) {},
    // 加载列表
    loadMeasureListData(page) {
      let data = {
        page,
        size: this.size,
        order: this.order,
      };
      getMeasureGroupList(data).then((res) => {
        //console.log(res);

        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    edit(id = false) {
      if (id !== false) {
        this.$router.push(`/measure/do_measure_group?id=${id}`);
      } else {
        this.$router.push(`/measure/do_measure_group`);
      }
    },
    remove(id) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // 删除一个分类数据
          delMeasureGroupList({ id }).then(async (res) => {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.loadMeasureListData();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    submitSort(row) {
      //console.log("row", row);
      let param = {
        id: row.id,
        sort: row.sort,
        groupName: row.group_name,
        status: row.status,
      };
      //console.log("param", param);
      this.updateApi(param);
    },
    updateApi(param) {
      updateMeasureGroupList(param).then(async (res) => {
        if (res.code === 400200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.dialog.dialogVisible = false;
        }
        this.loadMeasureListData();
      });
    },
    // 分组状态
    upstatus(row) {
      //console.log(row);
      let data = row;
      if (data.status === 1) {
        data.status = 2;
      } else {
        data.status = 1;
      }
      this.updateApi(data);
    },
    // 数据排序
    upSort() {
      if (this.order == "sort desc") {
        this.order = "sort";
      } else {
        this.order = "sort desc";
      }
      this.loadMeasureListData();
    },
  },
  filters: {
    statusFilter(val) {
      return val == 1 ? "启用" : "关闭";
    },
  },
};
</script>
<style lang="less">
// 二维码处理
.qr {
  display: flex;
  justify-content: space-around;
  text-align: center;
  h2 {
    margin-bottom: 10px;
    font-size: 16px;
    cursor: pointer;
  }
  .qr-show {
    color: green;
  }
  .qr-hide {
    color: red;
  }
}
.departmenselet {
  padding-top: 30px;
}
 .el-dialog__wrapper {
    .msgLoad {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
    }
    .measure-msg {
      color: black;
    }
    .queryTable {
      max-height: 500px;
      overflow: auto;
      table {
        width: 100%;
        transition: all 0.25s;
        &:hover {
          background: rgb(236, 235, 211);
        }
      }
      .queryTable-title {
        font-weight: 700;
        font-size: 14px;
        color: #044d5a;
      }
      .queryTable-item {
        margin-left: 30px;
        color: rgb(48, 47, 47);
      }
    }
  }
.tc-measure {
  width: 100%;
  min-width: 1300px;
  // 头部
  .header {
    width: 90%;
    margin: 0 auto;
    display: flex;
    padding-top: 20px;
    .header-operation {
      ul {
        display: flex;
        li {
          //  float: left;
          width: 180px;
          list-style: none;
          padding: 0 30px;
          text-align: center;
          img {
            width: 100%;
            // pointer-events: none;
          }
        }
      }
    }
    .header-tool {
      margin-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      .del {
        width: 120px;
        cursor: pointer;
      }
      .refresh {
        width: 120px;
        cursor: pointer;
      }
      img {
        width: 100%;
        pointer-events: none;
      }
    }
  }
  //   排序
  .sort {
    margin-top: 20px;
    height: 55px;
    background-color: rgb(#d9f2f7);
    display: flex;
    align-items: center;
    padding-left: 10px;
    .el-divider--vertical {
      height: 2em;
    }
    .el-button {
      background: transparent;
      color: rgb(#044d5a);
      font-size: 15px;
      border: 1px solid rgb(182, 226, 235);
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 550px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}
</style>